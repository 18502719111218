import { Box, Flex, Text, VStack } from "@chakra-ui/react";
import React, { useState } from "react";

import { route } from "nextjs-routes";

import useApiQuery from "lib/api/useApiQuery";
import { AddressHighlightProvider } from "lib/contexts/addressHighlight";
import useIsMobile from "lib/hooks/useIsMobile";
import useNewTxsSocket from "lib/hooks/useNewTxsSocket";
import { TX } from "stubs/tx";
import LinkInternal from "ui/shared/links/LinkInternal";
import SocketNewItemsNotice from "ui/shared/SocketNewItemsNotice";

import LatestTxsItem from "./LatestTxsItem";
import LatestTxsItemMobile from "./LatestTxsItemMobile";
import LatestTransaction from "ui/custom/lastest-transaction";

const LatestTransactions = () => {
  const isMobile = useIsMobile();
  const txsCount = isMobile ? 3 : 5;
  const { data, isPlaceholderData, isError } = useApiQuery("homepage_txs", {
    queryOptions: {
      placeholderData: Array(txsCount).fill(TX),
    },
  });

  const { num, socketAlert } = useNewTxsSocket();

  if (isError) {
    return <Text mt={4}>No data. Please reload page.</Text>;
  }

  if (data) {
    const txsUrl = route({ pathname: "/txs" });
    return (
      <>
        <SocketNewItemsNotice
          borderRadius="sm"
          bg="transparent"
          mb={2}
          url={txsUrl}
          num={num}
          alert={socketAlert}
          isLoading={isPlaceholderData}
        />
        <Box mb={3} display={{ base: "block", lg: "none" }}>
          {data.slice(0, txsCount).map((tx, index) => (
            <LatestTransaction
              key={tx.hash + (isPlaceholderData ? index : "")}
              tx={tx}
              isLoading={isPlaceholderData}
            />
          ))}
        </Box>
        <AddressHighlightProvider>
          <VStack spacing={2} mb={3} overflow="hidden" alignItems="stretch">
            {data.slice(0, txsCount).map((tx, index) => (
              <LatestTransaction
                key={tx.hash + (isPlaceholderData ? index : "")}
                tx={tx}
                isLoading={isPlaceholderData}
              />
            ))}
          </VStack>
        </AddressHighlightProvider>
        {/* <Flex justifyContent="center">
          <LinkInternal fontSize="sm" href={txsUrl}>
            View all transactions
          </LinkInternal>
        </Flex> */}
      </>
    );
  }

  return null;
};

export default LatestTransactions;
