import { Box, Flex, Grid, Skeleton, useTab } from "@chakra-ui/react";
import { motion } from "framer-motion";
import React from "react";

import type { Block } from "types/api/block";
import type { Transaction } from "types/api/transaction";

import config from "configs/app";
import getBlockTotalReward from "lib/block/getBlockTotalReward";
import getNetworkValidatorTitle from "lib/networks/getNetworkValidatorTitle";
import AddressEntity from "ui/shared/entities/address/AddressEntity";
import BlockEntity from "ui/shared/entities/block/BlockEntity";
import TimeAgoWithTooltip from "ui/shared/TimeAgoWithTooltip";
import { useTranslation } from "react-i18next";
import AddressFromTo from "ui/shared/address/AddressFromTo";
import getValueWithUnit from "lib/getValueWithUnit";
import { currencyUnits } from "lib/units";

type Props = {
  tx: Transaction;
  isLoading?: boolean;
};

const LatestTransaction = ({ tx, isLoading }: Props) => {
  const { t } = useTranslation();
  const dataTo = tx.to ? tx.to : tx.created_contract;

  return (
    <Box
      as={motion.div}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ display: "none" }}
      transitionDuration="normal"
      transitionTimingFunction="linear"
      borderRadius="sm"
      border="1px solid"
      borderColor="divider"
      p={3}
    >
      <Flex gap={4} fontSize="sm" alignItems="center">
        <Box>
          <BlockEntity
            isLoading={isLoading}
            number={tx.block}
            tailLength={2}
            lineHeight={7}
            fontWeight={500}
            mr="auto"
          />
          <TimeAgoWithTooltip
            timestamp={tx.timestamp}
            enableIncrement={!isLoading}
            isLoading={isLoading}
            color="text_secondary"
            fontWeight={400}
            display="inline-block"
            fontSize="sm"
            flexShrink={0}
            ml={8}
          />
        </Box>

        <Box flex={1}>
          <div className="flex gap-2 items-center">
            <span>From</span>

            <AddressEntity
              address={tx.from}
              isLoading={isLoading}
              noIcon
              noCopy
              truncation="constant"
            />
          </div>

          <div className="flex gap-2 items-center">
            <span>To</span>

            <AddressEntity
              address={tx.to}
              isLoading={isLoading}
              noIcon
              noCopy
              truncation="constant"
            />
          </div>
        </Box>

        <div>
          {getValueWithUnit(tx.value).dp(5).toFormat()} STOC
        </div>
      </Flex>
    </Box>
  );
};

export default LatestTransaction;
